<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { mapGetters,mapActions } from 'vuex';
import Swal from 'sweetalert2'
import EditForm from "./edit-form"

export default {
  page: {
    title: "Users",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    EditForm
  },
  data() {
    return {
      initializing: true,
      pagetitle: "Users",
      keyword: '',
      editItemId: null
    };
  },
  created(){
    this.init();
  },
  computed :{
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('users', {
      loading: 'loading',
      deleting: 'deleting',
      items: 'items',
      settings: 'settings'
    }),
    isBusy () {
      return this.loading || this.initializing;
    },
    showDeleted:
    {
      get: function() {
        return (this.settings || {}).showDeleted || false;
      },
      set: function(newValue) {
        this.writeSettings({...this.settings, showDeleted: newValue});
        this.load();
      }
    },
    mode: {
      get: function() {
        return (this.settings || {}).mode || 'list';
      },
      set: function(newValue) {
        this.writeSettings({...this.settings, mode: newValue});
      }
    },
    filteredItems () {
      let a = [...this.items];
      if(!this.showDeleted) { 
        a = a.filter(x => !x.deletedAt);
      }

      if(this.keyword) {
        a = a.filter(x => x.name.includes(this.keyword) || x.email.includes(this.keyword) || (x.phoneNumbers || '').includes(this.keyword)  || (x.description || '').includes(this.keyword));
      }
      return a;
    },
    allowEdit () {
      return (this.currentUser || {}).isAdmin;
    }
  },
  methods: {
    ...mapActions('users', {
      loadUsers: 'loadUsers',
      deleteUser: 'deleteUser',
      writeSettings: 'writeSettings',
      loadSettings: 'loadSettings'
    }),
    async init() {
      this.initializing = true;
      await this.loadSettings();
      this.initializing = false;
    },
    tryToDelete(user) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.deleteUser({id: user.id}).then(()=>{
            Swal.fire("Deleted!", "Record has been deleted.", "success");
          });
        }
      });
    },
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" :is-busy="isBusy">
   <edit-form v-if="editItemId" @close="editItemId = null" :id="editItemId">
    </edit-form>
    <div class="row align-items-center">
      <div class="col-md-12">
        <div
          class="d-flex flex-wrap align-items-center justify-content-end "
        >
          <div>
           <button
            v-if="currentUser.isAdmin"
              type="button"
              class="btn btn-primary"
              @click="editItemId = 'new'"
              >
                <eva-icon class="icon nav-icon me-1" name="plus-outline"></eva-icon>
                <div class="text-sm-end d-none d-md-inline-block ms-2">  {{ $t('common.newRecord') }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row" v-if="!initializing">
      <div class="col">
        <div class="table-responsive">
          <table
            class="table project-list-table table-nowrap align-middle table-borderless"
            style="margin-bottom:100px"
          >
            <thead>
              <tr>
                <th scope="col-auto"  v-if="currentUser.isAdmin"></th>
                <th scope="col">{{$t('users.nameField')}}</th>
                
                <th scope="col">{{$t('users.emailField')}}</th>
                <th scope="col">{{$t('users.phoneField')}}</th>
                <th scope="col">{{$t('users.roleField')}}</th>
                <th scope="col">{{$t('users.groupsField')}}</th>
                <th scope="col">{{$t('users.lockoutEndField')}}</th>
                <th scope="col">{{$t('users.descriptionField')}}</th>
                <th scope="col-auto"  v-if="currentUser.isAdmin"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="user in filteredItems" :key="user.id">
              <td  v-if="currentUser.isAdmin">
                  <a  href="javascript:void(0)" class="btn btn-light btn-sm" v-if="allowEdit && !user.deletedAt"  @click="editItemId = user.id">
                    <i class="bx bx-pencil me-1"></i> {{ $t("common.editItem") }}
                  </a>
                </td>
                <td>
                  <span v-if="!user.deletedAt">
                    {{user.name}}
                  </span>
                  <del v-if="user.deletedAt">
                    {{user.name}}
                  </del>
                </td>
                <td>
                  <span v-if="!user.deletedAt">
                    <a :href="'mailto:' + user.email">{{user.email}}</a>
                  </span>
                  <del v-if="user.deletedAt">
                    <a :href="'mailto:' + user.email">{{user.email}}</a>
                  </del>
                </td>
                <td>
                  <span v-if="!user.deletedAt">
                    <a v-if="user.phoneNumber" :href="'tel:'+user.phoneNumber">{{user.phoneNumber}}</a>
                  </span>
                  <del v-if="user.deletedAt">
                    <a v-if="user.phoneNumber" :href="'tel:'+user.phoneNumber">{{user.phoneNumber}}</a>
                  </del>
                </td>
                <td>
                  <span v-if="!user.deletedAt">
                    <span class=" badge bg-dark me-2" v-if="user.isOwner">Owner</span>
                    <span class="badge badge-soft-primary mb-0" v-if="user.isAdmin">Admin</span>
                  </span>
                  <del v-if="user.deletedAt">
                    <span class="badge bg-dark me-2" v-if="user.isOwner">Owner</span>
                    <span class="badge badge-soft-primary mb-0" v-if="user.isAdmin">Admin</span>
                  </del>
                </td>
                <td>
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item mx-2 bg-light py-1 px-2" v-for="group in user.groups.filter(x => !x.everyone)" :key="group.id">
                      {{group.name}}
                    </li>
                  </ul>
                </td>
                <td>
                  <span v-if="!user.deletedAt">
                    <span v-if="user.lockoutEnd">
                      {{$filters.shortDate(user.lockoutEnd)}}
                    </span>
                  </span>
                  <del v-if="user.deletedAt">
                    <span v-if="user.lockoutEnd">
                      {{$filters.shortDate(user.lockoutEnd)}}
                    </span>
                  </del>
                </td>
                <td>
                  {{user.description}}
                </td>
                <td v-if="currentUser.isAdmin" class="text-end">
                  <a
                  
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete"
                    @click="tryToDelete(user)"
                    class="px-2 text-danger btn btn-light btn-sm"
                    >
                    <i class="bx bx-trash-alt me-1"></i>
                  
                    {{ $t("directory.deleteItem") }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>
